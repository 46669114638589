import _ from "lodash";

const file_dl_url = $('div#file-list').data('download');

/**
 * generate spinner element
 *
 * @returns {JQuery<HtmlElement>}
 */
export function generateSpinner() {
    return $('<div class="text-center spinner" />').append('<div class="spinner-border spinner-border-lg" role="status"></div>');
}

/**
 * add tr to tbody
 *
 * @param {JQuery<HtmlElement>} tbody
 * @param {array} rowData
 * @param {string} fileType
 * @param {boolean} sendBtn
 */
export function setRows(tbody, rowData, fileType, sendBtn = false) {
    // tbody.children().not('.static').remove();

    for (let i = 0; i < rowData.length; i++) {
        const row = rowData[i];
        const tr = $('<tr />').data('path', row.path);

        const chkDxf = $('<td><input type="checkbox" /></td>');
        if (sendBtn == true) {
            chkDxf.appendTo(tr);
        }
        $('<td>').text(row.path).appendTo(tr);

        const btnTd = $('<td class="text-center" />');
        if (sendBtn == true) {
            btnTd.append($('<button type="button" class="btn btn-light btn-sm send">send</button>'));
        }
        $('<a class="btn btn-light btn-sm btn-download">download</a>')
            .attr('href', file_dl_url + '?' + $.param({type: fileType, path: row.path})).appendTo(btnTd);
        // btnTd.append($('<a class="btn btn-light btn-sm btn-download">download</a>'));
        btnTd.append($('<button type="button" class="btn btn-light btn-sm btn-delete-confirm">delete</button>'));
        btnTd.appendTo(tr);

        tbody.append(tr);
    }
}

/**
 * table rows refresh
 *
 * @param {JQuery<HTMLElement>} tbody
 */
export function reloadRows(tbody) {
    const fileType = tbody.data('fileType');
    const addBtn = fileType == 'dxf';
    const url = tbody.data('api');

    const table = tbody.parent('table');
    const spinner = generateSpinner();

    tbody.children().not('.static').remove();
    table.after(spinner);

    axios
        .get(url)
        .then((response) => {
            let rowData = _.orderBy(response.data, 'path', 'desc');
            setRows(tbody, rowData, fileType, addBtn);
            spinner.remove();
        })
        .catch((error) => {
            spinner.remove();
            alert(error.response.data.message);
        });

}

$(function () {

    // file list reload button click event
    $('#btn-filelist-reload').on('click', function() {
        const tBody = $(this).closest('table').children('tbody');
        reloadRows(tBody);
    });

    // delete button click event
    $(document).on('click', '.btn-delete-confirm', function () {
        const path = $(this).closest('tr').data('path');
        const tBody = $(this).closest('tbody');
        $('#btn-delete')
            .data('tableId', tBody.closest('table').attr('id'))
            .data('url', tBody.data('api'))
            .data('path', path);

        $('#delete-file-path').text(path);
        $('#modal-delete-conf').modal('show');
    });

    // delete button on dialog click event
    $('#btn-delete').on('click', function () {
        const url = $(this).data('url');
        const tbody = $('#' + $(this).data('tableId')).children('tbody');
        const params = {
            path: $(this).data('path'),
        };
        axios
            .delete(url, {data: params})
            .then((resopnse) => {
                reloadRows(tbody);
                $('#modal-delete-conf').modal('hide');
            })
            .catch((error) => {
                alert(error.response.data.message);
                $('#modal-delete-conf').modal('hide');
            });
    });
});
